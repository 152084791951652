import React from 'react';
import { useNavigate } from "react-router";
import { 
  PageContent, 
  PageHeader,
  Page,
  Anchor,
  Paragraph
} from 'grommet';
import { Previous } from 'grommet-icons';
import { HOME_ROUTE } from '../routes';

export const AboutUs = () => {
  const navigate = useNavigate();
  const BackLink = () => {
    return (
      <Anchor 
        label="Back" 
        icon={<Previous />}
        onClick={()=>{
          navigate(HOME_ROUTE);
        }}
      />
    )
  }
  return (
    <Page>
      <PageContent fill align="center" justify="center" background="light-3">
          <PageHeader title="About Us" parent={<BackLink />} />
          <Paragraph>
            Because Food Log was already taken.
            We're here to provide simple tools to help you throughout the day.  A food log doesn't need to be complicated, no Google or Apple apps to download and install (who knows what kind of spyware is installed along the way to keep track of your habits!), no fancy AI required here.  Just keep track of the foods you eat for whatever reason.  
          </Paragraph>
      </PageContent>
    </Page>
  );
}
