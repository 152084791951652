import DataAccess from './DataAccess';
import * as Constants from '../Constants';

/**
 * Intended to be the business object responsible for 
 * retrieving data in the FoodLog format
 */

class FoodLog extends DataAccess {
  constructor(schemaObj) {
    super();
    this.foodLogData = schemaObj;
  }

  async readAll() {
    return this.readAllObjects().then((data) => this.foodLogData = data);
  }
}

class FoodLogSchema {
  constructor(mealType, foodName, dateEaten, location, dateEaten_prev) {
    this[Constants.mealType] = mealType;
    this[Constants.foodName] = foodName;
    this[Constants.dateEaten] = dateEaten;
    this[Constants.location] = location;
    this[Constants.dateEaten_prev] = dateEaten_prev;
    this.version = Constants.VERSION;
  }
}

export { FoodLog, FoodLogSchema };