import React from 'react';
import { useNavigate } from "react-router";

import { DataTable, Button, Text } from 'grommet';
import { Trash, Edit } from 'grommet-icons';
import * as Constants from '../Constants';
import { EDIT_ROUTE } from '../routes';

export const List = ({data, allSelected, deleteSelection, size}) => {
  const [select, setSelect] = React.useState([]);
  const navigate = useNavigate();
  const columns = [
    {
      property: Constants.dateEaten,
      header: <Text>Date</Text>,
      render: (rowItem) => {
        return rowItem[Constants.dateEaten] && new Date(rowItem[Constants.dateEaten]).toLocaleDateString('en-US');
      },
      primary: true,
    },
    {
      property: Constants.mealType,
      header: <Text>When?</Text>,
    },
    {
      property: Constants.foodName,
      header: <Text>Type of Food</Text>,
      size: size
    },
    {
      property: "edit",
      header: "",
      render: (rowItem) => {
        return (
          <EditIcon keyAttr={rowItem.dateEaten} />
        )
      }
    },
    {
      property: "delete",
      header: "",
      render: (rowItem) => {
        return (
          <TrashIcon keyAttr={rowItem.dateEaten} />
        )
      }
    }
  ];
  
  const TrashIcon = ({keyAttr}) => {
    return (
      <Button hoverIndicator={{elevation: "medium"}} icon={<Trash />} onClick={(e)=>deleteSelection(keyAttr)}  />
    )
  }
  
  const EditIcon = ({keyAttr}) => {
    return (
      <Button 
        hoverIndicator={{elevation: "medium"}} 
        icon={<Edit />} 
        onClick={(e)=>{
          const item = data.find(e=>e.dateEaten === keyAttr);
          console.log("Found item", item);
          navigate(EDIT_ROUTE, {
            state: {data: item},
            replace: true,
          })
        }}  
      />
    )
  }
  
  const handleSelection = (s) => {
    console.log("selection made", s);
    setSelect(s);
    if (data && s && s.length == data.length) {
      allSelected(true);
    } else {
      allSelected(false);
    }
  }
  
  return (
    // <PageContent align="center" justify="center">
      // <Box>
        <DataTable
          allowSelectAll
          columns={columns}
          data={data}
          onSelect={handleSelection}
          select={select}
          sortable
          step={5}
          paginate
          //size={size}
        />
      // </Box>
    // </PageContent>
  );
};