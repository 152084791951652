import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, replace } from "react-router";
import { 
  Page,
  PageContent, 
  PageHeader,
  Grid,
  Box,
  Heading,
  Button,
  Spinner,
  Layer,
  ResponsiveContext,
  Text
} from 'grommet';
import { Add, Cafeteria, Trash } from 'grommet-icons';
import { FoodLog } from '../data';
import { ADD_ROUTE } from '../routes';
import { List } from './List';

const addNew = (navigate) => {
  navigate(ADD_ROUTE, {replace: true});
}

const Empty = ({navigate}) => {
  return (
    <Grid 
      rows={['flex', 'flex']}
      columns={['flex']}
      areas={[
        { name: 'top', start: [0, 0], end: [0, 0]},
        { name: 'bottom', start: [0, 1], end: [0, 1]}
      ]}
      gap="small" >
      <Box gridArea='top'>
        <Button onClick={()=>addNew(navigate)} plain={false} icon={<Add />} size='xlarge'></Button>
      </Box>
      <Box gridArea='bottom'>
        <Cafeteria size="xlarge" />
      </Box>
    </Grid>
  )
}

const Loading = () => {
  return (
    <Box gap="small">
      <Heading level={2}>The wheels are spinning...</Heading>
      <Spinner
        alignSelf='center'
        round="full"
        border={false}
        size="large"
        background="linear-gradient(to right, #fc466b, #3f5efb)"
      />
    </Box>
  )
}


export const Home = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  
  const welcomeTitle = "Your Food Log";
  const emptyTitle = "Start by adding something";
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=> {
    let flog = new FoodLog();
    flog.readAll().then((result)=>{
      setList(result);
      setLoading(false);
    });
  }, [location.key]);

  const deleteAll = () => {
    let flog = new FoodLog();
    flog.deleteAllObjects().then((result) => {
      setOpenDel(false);
      setShowDeleteAll(false);
      setList([]);
    })
  }  

  const deleteSelection = (dateEaten) => {
    let flog = new FoodLog();
    flog.deleteObject(dateEaten).then((result) => {
      //navigate(HOME_ROUTE);
      navigate(0, {replace: true});
    })
  }  

  const AddButton = ({navigate}) => {
    return (
      <Button margin={{right: "small"}} onClick={()=>addNew(navigate)} plain={false} icon={<Add />} size='small'></Button>
    )
  }
  
  const DeleteAllButton = () => {
    return (
      <Button onClick={()=>setOpenDel(true)} plain={false} icon={<Trash color='red' />} size='small'></Button>
    )
  }
  
  const DeleteAllLayer = () => {
    return (
      <Layer
        id="Delete All"
        position="center"
        onClickOutside={()=>setOpenDel(false)}
        onEsc={()=>setOpenDel(false)}
      >
        <Box pad="medium" gap="small" width="medium">
          <Heading level={3} margin="none">
            Confirm
          </Heading>
          <Text>Are you sure you want to delete EVERYTHING?</Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button label="Cancel" onClick={()=>setOpenDel(false)} color="dark-3" />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              onClick={()=>deleteAll()}
              primary
              color="status-critical"
            />
          </Box>
        </Box>
      </Layer>    
    )
  }
  
  const PageActions = () => {
    return (
      <Box alignSelf='center' direction="row" justify="evenly" >
        {list && list.length>0 ? <AddButton navigate={navigate}  /> : <></>}
        {showDeleteAll ? <DeleteAllButton /> : <></>}
      </Box>
    )
  }

  return (
    <Page>
      <PageContent fill align="center" background="light-3">
          <PageHeader
            responsive={true}
            title={list && list.length>0 ? welcomeTitle : emptyTitle } 
            actions={<PageActions />}   
          />
          {/* <List data={list} /> */}
          {openDel && (
            <DeleteAllLayer />
          )}
          {list && list.length > 0
            ?
            <List size={size} data={list} allSelected={setShowDeleteAll} deleteSelection={deleteSelection} />
            : 
            loading ? <Loading /> : <Empty navigate={navigate} />
          }
      </PageContent>
    </Page>
  );
}
