import { 
  VERSION, 
  IDB_OBJECTSTORE, 
  APP_OBJECTSTORE, 
  dateEaten, 
  dateEaten_prev,
 } from '../Constants';
 import * as Constants from '../Constants';
import { AppDataStoreSchema } from './AppDataStore';
/**
 * This class intended to be the abstract class for pulling data from
 * the source
 */

const DB_NAME = "FoodLog";
export default class DataAccess {
  constructor() {
    this.ACCESS_TYPE = "IndexDB";
    this.db = null;
    this.errorState = false;
    this.errorMessage = null;
  }

  async openDB() {
    return new Promise(function(resolve, reject) {
      let request = window.indexedDB.open(DB_NAME, VERSION);
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = "Error opening IndexDB.";
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        resolve(request.result);
      };
      request.onupgradeneeded = (event) => {
        //auto key generator. 
        const objectStore = event.target.result.createObjectStore(IDB_OBJECTSTORE, { keyPath: dateEaten });
        objectStore.createIndex(IDB_OBJECTSTORE, dateEaten, { unique: true });

        const appDataStore = event.target.result.createObjectStore(APP_OBJECTSTORE, { keyPath: Constants.version_attr });
        appDataStore.transaction.oncomplete = (evt2) => {
          const innerAppDataStore = event.target.result.transaction([APP_OBJECTSTORE], "readwrite").objectStore(APP_OBJECTSTORE);
          innerAppDataStore.add(new AppDataStoreSchema());
          //do not need onsuccess because i'm asuming this will succeed the very first time! 
        }
      }
    })
  }

  async setData(data, editMode) {
    if (this.db === null) {
      this.db = await this.openDB();
    }
    const transaction = this.db.transaction([IDB_OBJECTSTORE], "readwrite");

    if (editMode) {
      //if editmode, then user might have changed the dateEaten.  we need to use the previous value to delete it
      const request = transaction.objectStore(IDB_OBJECTSTORE).delete(data[dateEaten_prev]);
      return new Promise((resolve, reject)=> {
        request.onerror = (event) => {
          this.errorState = true;
          this.errorMessage = "Error attempting to delete data in setData editMode.";
          reject(this.errorMessage);
        };
        request.onsuccess = (event) => {
          console.log("setData successfully deleted old record", event.target.result);
          let newData = {}
          Object.assign(newData, data);
          delete newData.dateEaten_prev;
          const addNewRequest = transaction.objectStore(IDB_OBJECTSTORE).add(newData);
          addNewRequest.onerror = (e2) => {
            transaction.abort();
            this.errorState = true;
            this.errorMessage = "Error attempting to add new data in editMode.";
            console.log(e2);
            reject(this.errorMessage);
          };
          addNewRequest.onsuccess = (e2) => {
            console.log("setData successfully added new record", event.target.result);
            resolve(e2.target.result);
          };
        }
      });
    } else {
      // console.log("DataAccess new Data", data);
      const request = transaction.objectStore(IDB_OBJECTSTORE).add(data);
      return new Promise((resolve, reject)=> {
        request.onerror = (event) => {
          this.errorState = true;
          this.errorMessage = "Error calling setData.";
          reject(event);
        };
        request.onsuccess = (event) => {
          console.log("setData returning", event.target.result);
          resolve(event.target.result);
        }
      });  
    }
  }

  async readAllObjects() {
    if (this.db === null) {
      this.db = await this.openDB();
    }
    const request = this.db.transaction([IDB_OBJECTSTORE], "readonly").objectStore(IDB_OBJECTSTORE).getAll();
    return new Promise((resolve, reject)=> {
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = "Error reading all.";
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        console.log("readAllObjects returning", JSON.stringify(event.target.result));
        resolve(event.target.result);
      }
    })
  }

  async deleteAllObjects() {
    if (this.db === null) {
      this.db = await this.openDB();
    }
    const request = this.db.transaction([IDB_OBJECTSTORE], "readwrite").objectStore(IDB_OBJECTSTORE).clear();
    return new Promise((resolve, reject)=> {
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = "Error clearing all.";
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        console.log("deleteAllObjects returning", event.target.result);
        resolve(event.target.result);
      }
    })
  }

  async deleteObject(key) {
    if (this.db === null) {
      this.db = await this.openDB();
    }
    console.log("DataAccess-Attempting to delete:", key);
    const request = this.db.transaction([IDB_OBJECTSTORE], "readwrite").objectStore(IDB_OBJECTSTORE).delete(key);
    return new Promise((resolve, reject)=> {
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = "Error deleting with pk: " + key;
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        console.log("deleteObject returning", event.target.result);
        resolve(event.target.result);
      }
    })
  }

}