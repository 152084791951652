import DataAccess from './DataAccess';
import * as Constants from '../Constants';

/**
 * Intended to be the business object responsible for 
 * retrieving data in the FoodLog format
 */

class AppDataStore {
  constructor(schemaObj) {
    // super();
    this.appDataStore = schemaObj;
  }

  async read(version = 1) {
    let da = new DataAccess();
    let db = await da.openDB();
    console.log("AppDataStore--attempting to read with version", version);
    const request = db.transaction([Constants.APP_OBJECTSTORE], "readonly").objectStore(Constants.APP_OBJECTSTORE).get(version);
    return new Promise((resolve, reject)=> {
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = `Error reading ${Constants.APP_OBJECTSTORE}.`;
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        console.log(`readAll on ${Constants.APP_OBJECTSTORE} returning`, JSON.stringify(event.target.result));
        let schemaObj = {}; //new AppDataStoreScehma();
        schemaObj[Constants.install_date] = event.target.result[Constants.install_date];
        schemaObj[Constants.install_location] = event.target.result[Constants.install_location];
        schemaObj[Constants.uninstall_date] = event.target.result[Constants.uninstall_date];
        schemaObj[Constants.fs_uid] = event.target.result[Constants.fs_uid];
        schemaObj[Constants.version_attr] = event.target.result[Constants.version_attr];
        resolve(schemaObj);
      }
    })
  }

  async update(inputData) {
    let da = new DataAccess();
    let db = await da.openDB();
    const appStore = db.transaction([Constants.APP_OBJECTSTORE], "readwrite").objectStore(Constants.APP_OBJECTSTORE); 
    console.log("attempting to read appdatastore using ", inputData[Constants.version_attr]);
    const request = appStore.get(inputData[Constants.version_attr]);
    return new Promise((resolve, reject)=> {
      request.onerror = (event) => {
        this.errorState = true;
        this.errorMessage = `Error reading ${Constants.APP_OBJECTSTORE}.`;
        reject(this.errorMessage);
      };
      request.onsuccess = (event) => {
        let oldData = event.target.result;
        oldData[Constants.uninstall_date] = this.assign(oldData, inputData, Constants.uninstall_date);
        oldData[Constants.install_date] = this.assign(oldData, inputData, Constants.install_date);
        oldData[Constants.install_location] = this.assign(oldData, inputData, Constants.install_location);
        oldData[Constants.fs_uid] = this.assign(oldData, inputData, Constants.fs_uid);
        //TODO assume VERSION 1 for now.  that's why just updating 'oldData' object with 
        //the other attributes.
        const updateRequest = appStore.put(oldData);
        

        updateRequest.onerror = (e2) => {
          this.errorState = true;
          this.errorMessage = "Error attempting to update AppDataStore.";
          console.log(e2);
          reject(this.errorMessage);
        };
        updateRequest.onsuccess = (e2) => {
          console.log("setData successfully updated AppDataStore", event.target.result);
          resolve(e2.target.result);
        };
      }
    })
  }

  assign(oldObj, newObj, attr) {
    if (oldObj[attr] === newObj[attr]) {
      return oldObj[attr]
    } else {
      return newObj[attr]
    }
  }


}

class AppDataStoreSchema {
  constructor(installDate="", uninstallDate="", fsUID, install_location) {
    this[Constants.install_date] = installDate;
    this[Constants.uninstall_date] = uninstallDate;
    this[Constants.fs_uid] = fsUID;
    this[Constants.install_location] = install_location;
    this[Constants.version_attr] = Constants.VERSION;
  }
}

export { AppDataStore, AppDataStoreSchema };