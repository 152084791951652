import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router";
// import App from './App';
import { AppGrid } from './AppGrid';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AppDataStore, AppDataStoreSchema } from './data/AppDataStore';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInAnonymously, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUOb6Uh6owhXOxR3xgEsGFbc9tkmAiyns",
  authDomain: "phoodlog1.firebaseapp.com",
  projectId: "phoodlog1",
  storageBucket: "phoodlog1.firebasestorage.app",
  messagingSenderId: "1099478533019",
  appId: "1:1099478533019:web:8d3b626200e0bb946ed73e",
  measurementId: "G-G5TB8KZP79"
};

// Initialize Firebase.  TODO.  ideally only want to do this if the
// user has agreed to sign up so we can keep track of unique logins
const app = initializeApp(firebaseConfig);
const fsDB = initializeFirestore(app, {
  ignoreUndefinedProperties: true
});
// const fsDB = getFirestore(app);
// const analytics = getAnalytics(app);

export const FirestoreDBContext = createContext();
export const AuthContext = createContext();

const auth = getAuth(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
// var appAlreadyInstalled = false;

//TODO no point in storing this local variable as the browser already knows
//and it would just mess up my install event logic for adding a record in firestore.
//let installedStr = localStorage.getItem("appInstalledOnChrome");
// if (installedStr && installedStr === "true") {
//   appAlreadyInstalled = true;
// }

const RootComponent = () => {
  const [appAlreadyInstalled, setAppAlreadyInstalled] = useState(false);
  const [userObj, setUserObj] = useState(auth.currentUser);
  const appInstalledCB = () => {
    localStorage.setItem("appInstalledOnChrome", "true");
    let appData = new AppDataStoreSchema();
    appData.install_date = new Date().toISOString();
    appData.fs_uid = anonUID;
    if (!navigator.geolocation) {
      console.log("geolocation not found in navigator");
      new AppDataStore().update(appData).then((result)=> {
        setAppAlreadyInstalled(true);
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let installLoc = position.coords.latitude+","+position.coords.longitude;
          appData.install_location = installLoc;
          console.log("installLoc is", installLoc)
          new AppDataStore().update(appData).then((result)=> {
            setAppAlreadyInstalled(true);
          });
        }
        , 
        ()=>{
          console.log("Unable to get geolocation");
          new AppDataStore().update(appData).then((result)=> {
            setAppAlreadyInstalled(true);
          });
        });
    }
  }
  console.log("userObj is ", userObj);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // anonUID = user.uid;
      console.log("User UID signed in", user.uid);
      // console.log("User object signed in", user);
      setUserObj(user);
    } else {
      // User is signed out
      setUserObj(null);
      console.log("User has signed out");
      signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("Signed in anonymously.")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error signing in", errorCode, errorMessage);
      });
    }
  });
  
  window.addEventListener('appinstalled', function(evt) {
    console.log("appinstalled event", evt);
    localStorage.setItem("appInstalledOnChrome", "true");
    //Do not bother calling this callback here.  It will be called when the user 
    //accepts the choice to install.  This is because dev mode does not support the appinstalled event.
    // appInstalledCB();  
  });
  
  //React.StrictMode renders twice, making it look like
  //firebase is inserting two records. This only happens in development mode.
  return (
  // <React.StrictMode>
    <FirestoreDBContext.Provider value={fsDB}>
      <AuthContext.Provider value={userObj}>
        <BrowserRouter>
          <AppGrid appInstalled={appAlreadyInstalled} installCallBack={appInstalledCB} />
        </BrowserRouter>
      </AuthContext.Provider>
    </FirestoreDBContext.Provider>
  // </React.StrictMode>
  );

} //end RootComponent

root.render(
  <RootComponent />
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if (process.env.NODE_ENV !== "development") {
//   console.log = ()=> {};
// }