import React from 'react';
import { useNavigate } from "react-router";
import {
  Page,
  PageContent, 
  PageHeader,
  Grid,
  Anchor
} from 'grommet';
import { Previous } from 'grommet-icons';
import { AddForm } from './AddForm';
import { FoodLog } from '../data/FoodLog';
import { HOME_ROUTE } from '../routes';

const saveToDB = async (data) => {
  let flObj = new FoodLog();
  return flObj.setData(data);
}

export const Add = () => {
  
  const navigate = useNavigate();
  const BackLink = () => {
    return (
      <Anchor 
        label="Back" 
        icon={<Previous />}
        onClick={()=>{
          navigate(HOME_ROUTE, { replace: true });
        }}
      />
    )
  }
  return (
    <Page>
      <PageContent align="center">
          <PageHeader gridArea='header' title="Add your log" parent={<BackLink />}/>      
          <Grid 
            rows={['flex', 'medium']}
            columns={['flex']}
            areas={[
              { name: 'header', start: [0, 0], end: [0, 0]},
              { name: 'mainForm', start: [0, 1], end: [0, 1]}
            ]}
            gap="small" >
              <AddForm navigate={navigate} storeData={saveToDB} />
          </Grid>
          
          
      </PageContent>
    </Page>
  );
}
