export const VERSION = 1;


//DB attributes
export const key = "key";
export const dateEaten = "dateEaten";
export const mealType = "mealType";
export const foodName = "foodName";
export const location = "location";
export const dateEaten_prev = "dateEaten_prev";
export const version_attr = 'version';

export const IDB_OBJECTSTORE = "FoodLogStore"
export const APP_OBJECTSTORE = "AppDataStore";

//constants for generic aplication wide datastore attributes.
export const install_date = "install_date";
export const uninstall_date = "uninstall_date";
export const install_location = "install_location";
//the firestore unique id
export const fs_uid = "fs_uid";
