import React, { useEffect, useState, useContext } from "react";
import {
  ResponsiveContext,
  Box,
  Button,
  Form,
  Calendar,
  RadioButtonGroup,
  Text,
  TextInput,
} from "grommet";
import { FoodLogSchema } from '../data/FoodLog';
import * as Constants from '../Constants';
import { HOME_ROUTE } from '../routes';

export const AddForm = ({storeData, navigate, initData}) => {
  const size = useContext(ResponsiveContext);
  const [mealType, setMealType] = useState(null);
  const [foodName, setFoodName] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [buttonBusy, setButtonBusy] = useState(false);
  const [latLongCoords, setLatLongCoords] = useState("");
  
  useEffect(() => {
    if (initData) {
      setMealType(initData[Constants.mealType]);
      setFoodName(initData[Constants.foodName]);
      setSelectedDate(initData[Constants.dateEaten]);
      setLatLongCoords(initData[Constants.location]);
    }

    if (!navigator.geolocation) {
      console.log("geolocation not found in navigator");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLongCoords(position.coords.latitude+","+position.coords.longitude);
          console.log("setting coords", position.coords.latitude+","+position.coords.longitude)
        }
        , 
        ()=>{
          console.log("Unable to get geolocation");
        });
    }

  }, [])

  const handleSelection = (dateSelected) => {
    // console.log(dateSelected);

    // Parse the input date
    const inputDate = new Date(dateSelected);

    if (isNaN(inputDate.getTime())) {
        throw new Error("Invalid ISO8601 date string");
    }

    // Get the current time
    const now = new Date();

    // Create a new date object with the same year, month, and day as the input date
    const updatedDate = new Date(
        inputDate.getFullYear(),
        inputDate.getMonth(),
        inputDate.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds()
    );

    // Return the updated date as an ISO8601 string
    setSelectedDate(updatedDate.toISOString());
  }
  const postMethods = [
    { 
      label: 'Morning', 
      value: 'morning' 
    },
    {
      label: 'Mid Morning',
      value: 'mid-morning',
    },
    {
      label: 'Afternoon',
      value: 'afternoon',
    },
    {
      label: 'Evening',
      value: 'evening',
    },
    {
      label: 'Late Evening',
      value: 'late-evening',
    },
  ];
  
  const handleSubmit = event => {
    event.preventDefault();
    setButtonBusy(true);
    let flObj = new FoodLogSchema();
    flObj[Constants.dateEaten] = selectedDate;
    flObj[Constants.foodName] = foodName;
    flObj[Constants.mealType] = mealType;
    flObj[Constants.location] = latLongCoords;
    if (initData) {
      flObj[Constants.dateEaten_prev] = initData.dateEaten;
    }
    storeData(flObj).then((success) => {
      setButtonBusy(false);
        navigate(HOME_ROUTE, { replace: true });
    }).catch((error) => {
      console.log("Caught error!", error);
    });
  };

  return (
    <Box
      gridArea="mainForm"
      onDrop={event => event.preventDefault()} // Prevent document drop from overtaking window
    >
      <Form onSubmit={handleSubmit}>
        {/* <Box> */}
          <Text weight={"bold"} size="medium">When did you eat it?</Text>
          <RadioButtonGroup direction="row-responsive"
            name="mealType"
            options={postMethods}
            value={mealType}
            onChange={(event) => setMealType(event.value)}
            gap="xlarge"
            
          />
        {/* </Box> */}
        {/* <Box> */}
          <Text weight={"bold"} size={size}>What did you eat?</Text>
          <TextInput name="foodName" value={foodName} onChange={(e)=>setFoodName(e.target.value)} aria-label="Food Name" />
        {/* </Box> */}
        {/* <Box> */}
          <Text weight={"bold"} size={size}>What date did you eat it?</Text>
          <Calendar fill size={size} daysOfWeek animate date={selectedDate} onSelect={(e)=>handleSelection(e)} />
        {/* </Box> */}
        <Box  justify="end" align="center" direction="row-responsive">
          <Button busy={buttonBusy} size={size} type="submit" label="Save" primary onClick={handleSubmit} />
        </Box>
      </Form>
    </Box>
  );
};
