import { react, useEffect, useRef, useState, useContext } from 'react';
import { 
  Box,
  Grommet, 
  Button,
  grommet,
  Text,
  Nav,
  Anchor,
  Grid,
  Layer,
  DropButton,
  Main,
  Avatar,
  Tip} from 'grommet';
import { Login, Logout, User } from "grommet-icons";
import * as firebaseui from 'firebaseui';
import { 
  getAuth, 
  onAuthStateChanged, 
  signInAnonymously, 
  signInWithPopup,
  signOut,
  GoogleAuthProvider, 
  FacebookAuthProvider 
} from "firebase/auth";
import { AuthContext } from '../..';


// const DropContent = ({postShow}) => {
//   const childRef = useRef(null);
//   useEffect(()=>{
//     console.log("postShow");
//     if (childRef.current) {
//       console.log("postShow2");
//       //postShow();
//     }
//   },[]);
//   console.log("DropContent is returning...");
//   return (
//     <Box ref={childRef} id="firebaseui-auth-container">
//         <div>something</div>
//     </Box>
//   )
// }

export const AuthButton = () => {
  const [openDel, setOpenDel] = useState(false);
  const [dropButtonOpen, setDropButtonOpen] = useState(false);
  const userObj = useContext(AuthContext);
  // console.log("AuthButton. Starting userObj", userObj);
  var auth = getAuth();
  // console.log("AuthButton->currentUser:", auth.currentUser);
  const handleLogin = () => {
    setOpenDel(true);

    const gProvider = new GoogleAuthProvider();
    signInWithPopup(auth, gProvider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("Error authenticating with Google", error);
      // ...
    }).finally((e)=>{
      console.log("FINALLY", e);
    });
    // var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    // ui.start('#firebaseui-auth-container', {
    //   callbacks: {
    //     signInSuccessWithAuthResult: function(authResult, redirectUrl) {
    //       // User successfully signed in.
    //       // Return type determines whether we continue the redirect automatically
    //       // or whether we leave that to developer to handle.
    //       console.log("Successfully logged in", authResult, redirectUrl);
    //       return true;
    //     },
    //     uiShown: function() {
    //       // The widget is rendered.
    //       // Hide the loader.
    //       console.log("uiShown");
    //     }
    //   },
    //   signInFlow: 'popup',
    //   signInOptions: [
    //     GoogleAuthProvider.PROVIDER_ID
    //   ],
    //   // Other config options...
    // });
  }
  const handleLoginOpen = () => {
    handleLogin();
  }

  const handleLogout = () => {
    signOut(auth).then(()=> {
      console.log("signed out");
    }).catch((err)=>{
      console.log("error signing out");
    })
  }
  const LoginButton = () => {
    return (
      <Button
        a11yTitle="Login with Google"
        icon={<Login />}
        onClick={()=>{handleLoginOpen()}}
        disabled={false}
        tip={{
          content: (
            <Box
              pad="small"
              round="small"
              background={"light-3"}
            >
              Login with Google
            </Box>
          ),
          plain: true,
        }}
      />
    )
    //TODO enable when multiple IDP providers are added.
    // return (
    //   <DropButton
    //     icon={<Login />}
    //     label="Login"
    //     open={dropButtonOpen}
    //     onOpen={()=>setDropButtonOpen(true)}
    //     onClose={()=>setDropButtonOpen(false)}
    //     dropContent={<DropContent postShow={handleLoginOpen} />}
    //   />
    // )
  };
  const LogoutButton = ({photoURL}) => {
    return (
      <Button
        a11yTitle="Chrome Install"
        icon={ photoURL && <User /> || <Logout />}
        onClick={()=>{}}
        disabled={false}
        tip={{
          content: (
            <Box
              pad="small"
              round="small"
              background={"light-3"}
            >
              Click to logout
            </Box>
          ),
          plain: true,
        }}
      />
    )
  };
  const LoginOptionsLayer = () => {
    return (
      <Layer
        id="Auth Method"
        position="center"
        onClickOutside={()=>setOpenDel(false)}
        onEsc={()=>setOpenDel(false)}
      >
        <Box id="firebaseui-auth-container" pad="medium" gap="small" width="medium">
        </Box>
      </Layer>    
    )
  }



  return (
    userObj && !userObj.isAnonymous
      ?
      <LogoutButton photoURL={userObj.photoURL} />
      :
      <Box>
        <LoginButton />
      </Box>
  );
}