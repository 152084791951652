import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router";
import { 
  Box,
  Grommet, 
  Button,
  grommet,
  Text,
  Nav,
  Anchor,
  Grid,
  Main} from 'grommet';
import { deepMerge } from "grommet/utils";
import { InstallOption } from "grommet-icons";
import { ABOUTUS_ROUTE, ADD_ROUTE, EDIT_ROUTE, HOME_ROUTE } from './routes';
import { Home, Add, Edit } from "./components";
import { AboutUs } from "./components/AboutUs";
import { FirestoreDBContext } from ".";
import { AppDataStore, AppDataStoreSchema } from './data/AppDataStore';
import { collection, addDoc } from "firebase/firestore"; 
import { AuthButton } from "./components/auth/AuthButton";

export const AppGrid = ({appInstalled, installCallBack}) => {
  const fsDB = useContext(FirestoreDBContext);
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const handleInstallWebApp = () => {
    const deferredPrompt = window.deferredPrompt;
    if (deferredPrompt) {
      console.log("Prompting...");
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          //we'll use this callback function because in dev mode, the appInstalled event
          //is not fired. So why have two mechanisms? Just use this one for both prod and dev.
          installCallBack && installCallBack();
        } else {
          console.log('User dismissed the install prompt');
        }
        window.deferredPrompt = null;
      });
    }
  };

  useEffect(()=> {
    console.log("AppGrid useEffect for appInstalled called", appInstalled);
    if (appInstalled) {
      //update fs context for the installation
      new AppDataStore().read().then((result)=>{
        let schemaObj = new AppDataStoreSchema();
        schemaObj = result;
        //add firestore collection
        if (fsDB) {
          // console.log("firebase initialized?", fsDB);
          try {
            const docRef = addDoc(
              collection(fsDB, "installations"), 
              schemaObj).then((result)=>{
                console.log("Document written with ID: ", result.id);
            });
          } catch (e) {
            console.error("Error adding document: ", e);
          }
      
        } else {
          console.log("firebase not yet initialized");
        }        
      })
    }
  }, [appInstalled])
  
  useEffect(() => {
    console.log("AppGrid useEffect called for handleBeforeInstallPrompt");
    const handleBeforeInstallPrompt = (event) => {
      console.log("handleBeforeInstallPrompt called", event);
      event.preventDefault();
      window.deferredPrompt = event;
      setInstallDisabled(false);
    };  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const theme = deepMerge(grommet, {
    global: {
      spacing: "5px",
    },
  });
  const [installDisabled, setInstallDisabled] = useState(true);

  return (
    <Grommet theme={grommet} full themeMode="light">
      <Grid
        fill
        rows={['auto', 'flex']}
        columns={['auto', 'flex']}
        areas={[
          { name: 'header', start: [0, 0], end: [1, 0] },
          { name: 'sidebar', start: [0, 1], end: [0, 1] },
          { name: 'main', start: [1, 1], end: [1, 1] },
        ]}
      >
        <Box
          gridArea="header"
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          background="brand"
        >
          <Button href="https://phoodlog.com" >
            <Text size="large">Phood Log</Text>
          </Button>
          <Nav align="center" direction="row">
            <Anchor 
              alignSelf="center" 
              gap="small" 
              label="About Us" 
              key="About Us"
              onClick={()=> {
                navigate(ABOUTUS_ROUTE);
              }} />
            <AuthButton />
            <Button
              a11yTitle="Chrome Install"
              icon={<InstallOption />}
              onClick={handleInstallWebApp}
              disabled={installDisabled && !appInstalled}
              tip={{
                content: (
                  <Box
                    pad="small"
                    round="small"
                    background={"light-3"}
                  >
                    Click to install on Chrome
                  </Box>
                ),
                plain: true,
              }}
            />
          </Nav>
        </Box>
        {sidebar && (
          <Box
            gridArea="sidebar"
            background="dark-3"
            width="small"
            animation={[
              { type: 'fadeIn', duration: 300 },
              { type: 'slideRight', size: 'xlarge', duration: 150 },
            ]}
          >
            {['First', 'Second', 'Third'].map((name) => (
              <Button key={name} href="#" hoverIndicator>
                <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
                  <Text>{name}</Text>
                </Box>
              </Button>
            ))}
          </Box>
        )}
        <Box  background="light-2" gridArea="main" justify="center" align="center" border="all">
          <Main background="light-3" fill>
            <Routes>
              <Route path={HOME_ROUTE} element={<Home />} />
              <Route path={ADD_ROUTE} element={<Add />} />
              <Route path={EDIT_ROUTE} element={<Edit />} />
              <Route path={ABOUTUS_ROUTE} element={<AboutUs />} /> 
            </Routes>
          </Main>
        </Box>
      </Grid>
    </Grommet>
  );
};